<template>
  <div id="app">
  
    <div id="navbar">

      <nav class="navbar navbar-expand-lg navbar-light bg-light">
  
        <div class="container-fluid">
  
          <!-- <a class="navbar-brand" href="#">aQuadrat</a> -->

          <!-- <a class="navbar-brand" href="#">
            <img src="./assets/logo.jpg" width="30" height="30" alt="">
          </a> -->

          <router-link class="nav-link active" aria-current="page" to="/">
            <img src="./assets/logo.jpg" width="30" height="30" alt="">
          </router-link>
  
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>  
          </button>
  
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
  
              <li class="nav-item">
                <router-link class="nav-link active" aria-current="page" to="/">Startseite</router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link active" aria-current="page" to="/kontakt">Kontakt</router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link active" aria-current="page" to="/impressum">Impressum</router-link>
              </li>
            </ul>
      
            <!-- <form class="d-flex">
              <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
              <button class="btn btn-outline-success" type="submit">Search</button>
            </form> -->
    
          </div>
  
        </div>

    </nav>

    </div>
  
  </div>

  <div id="main">
    <router-view/>
  </div>


</template>

<script>

export default {
  name: 'App',
}
</script>


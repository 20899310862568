import { createRouter, createWebHistory} from 'vue-router';
import LandingView from './components/LandingView.vue';
import KontaktView from './components/KontaktView.vue';
import ImpressumView from './components/ImpressumView.vue';



const routes = [

    {
        path: '/',
        name: 'Home',
        component: LandingView
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: KontaktView
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: ImpressumView
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
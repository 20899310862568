<template lang="html">

    <div class="container">
        <div class="row align-items-center mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header" >
                        <i class="fa fa-solid fa-user-tie"></i> Kleine und mittlere Betriebe suche ideale Auszubildende 
                    </div>
                    <div class="card-body" >
                        <p>
                            Deutschland leidet unter akutem Lehrlingsmangel. Dies liegt zu einem nicht unerheblichen Teil daran, 
                            dass das mittelständische Handwerksbetriebe in der Wahrnehmung der kommenden Auszubildenden nicht vorkommen. 
                            Große Industrieunternehmen sorgen mit erheblichem finanziellen Aufwand für Aufmerksamkeit in eigener Sache. 
                            Diese Ressourcen stehen mittelständischen Handwerksbetrieben nicht zur Verfügung. <br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-3">
            &nbsp;
        </div>
    </div>

    <div class="container">
        <div class="row align-items-center mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header" >
                        <i class="fa fa-solid fa-user"></i> Auszubildende suchen ideale Betriebe 
                    </div>
                    <div class="card-body" >
                        <p>
                            Junge Menschen, die eine Ausbildung suchen, sehen sich der aufwändigen Maschinerie von großen Unternehmen ausgesetzt.
                            Aber vielleicht passt der vergleichsweise kleine Ausbildungsbetrieb um die Ecke ja viel besser zu den eigenen Vorstellungen,
                            Werten und Ansprüchen. Der potenzielle Auszubildende weiß nur leider nichts davon.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-3">
            &nbsp;
        </div>
    </div>

    <div class="container">
        <div class="row align-items-center mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header" >
                        <i class="fa fa-solid fa-people-arrows"></i> Der ideale Ausbildungsplatz für den idealen Auszubildenden
                    </div>
                    <div class="card-body" >
                        <p>
                            Unsere Software erfragt die persönlichen Werte eines potenziellen Auszubildenden und ein paar Details zu Interessensgebieten und geografischen Vorlieben.
                            Aus diesen Werten entsteht für jeden potenziellen eine Wertekarte, die wir gegen jeden ausgeschriebenen Ausbildungsplatz in unserem Universum
                            halten. Je mehr Gemeinsamkeiten auf beiden Seiten bestehen, desto höher das Scoring (in Prozent).
                        </p>
                        <p>Was hilfts?
                            <br>
                        Ausbildungsbetriebe sehen das Wertesystem von zukünftigen potenziellen Auszubildenden und können diese gezielt ansprechen - falls sie passen.
                        <br>
                        Menschen auf der Suche nach einer Ausbildung sehen, dass es vielleicht Betriebe gibt, die super zu ihnen passen, die sie aber nie "auf dem Schirm" hatten.</p>
                        <p 
                            align="center">Einfach ausprobieren 
                            <br>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.mein-idealer-job.de">www.dein-idealer-job.de</a>
                        </p>

                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-3">
            &nbsp;
        </div>
    </div>

    <div class="container">
        <div class="row align-items-center mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header" >
                        <i class="fa fa-solid fa-play"></i> Die Expertise: Ein Startup mit 30 Jahren Erfahrung
                    </div>
                    <div class="card-body" >
                        <p>
                            Zusammen mit unserem Partner 
                            <a href="https://www.opus-consult.de/" target="_blank">Opus Consult</a> 
                            kommen wir von klassischen psychologischen Einstellungstest und haben mittlerweile hunderte von potenziellen Lehrlingen zu deren Werten getestet.
                            Während der Jahre wurde unser auf 52 Einzelfragen basierender Fragebogen über die Jahre immer wieder getestet und verbessert. <br>
                            Aber es wurden immer die bestehenden Bewerber für die jeweilige Stelle getestet. Was aber, wenn man die Fragen allen stellt und dann die
                            Bewerber anspricht? Der Teich, aus dem potenzielle Bewerber kommen können wird deutlich größer. 
                            Internet und schnelle Server machen genau dieses Szenario möglich. Sprechen sie uns an.
                        </p>


                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-3">
            &nbsp;
        </div>
    </div> 

    </template>

<script>
    export default {

    }
</script>

<style scoped>

</style>
<template lang="html">

    <div class="container">
        <div class="row align-items-center mt-3">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header" >
                        <i class="fa fa-solid fa-globe"></i> Impressum 
                    </div>
                    <div class="card-body" >
                        <p>
                            Anbieter:<br />
                            aquadrat GbR<br />
                            Pfaffenschlag 10<br />
                            96114 Hirschaid
                        </p>
                        <p>
                            Kontakt:<br />
                            Telefon: + 49 (0) 911 92 86 76 52<br />
                            Telefax: + 49 (0) 911 92 86 76 53<br />
                            E-Mail: Florian@Leuchtenstern.com<br />
                            Websites der Gesellschaft: <router-link to="/home">www.a2-new-work.de</router-link><br>
                            Produktwebsite: <a target="_blank" rel="noopener noreferrer" href="httPs://www.mein-idealer-job.de">www.dein-idealer-job.de</a></p>
                        <p></p>
                        <p>Bei redaktionellen Inhalten:</p>
                        <p>Verantwortlich nach § 55 Abs.2 RStV<br />Florian Leuchtenstern<br />Beim Wahlbaum 73<br />90453 Nürnberg</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mt-3">
            &nbsp;
        </div>
        </div>
    </template>

<script>
    export default {

    }
</script>

<style scoped>

</style>